<template>
	<nav class="container-fluid">
		<div class="content-wrapper d-flex justify-content-between align-items-center">
			<router-link class="go-back-wrapper d-flex align-items-center" to="/kurzy">
				<p class="z-fw-1">Kurzy</p>
			</router-link>
			<div class="links-wrapper">
				<router-link v-if="!this.isLoggedIn" :to="`/prihlasit-sa?navigateTo=${navigateToAfterLogin}`">Prihlásiť sa</router-link>
				<router-link v-else to="/profil">Profil</router-link>
			</div>
		</div>
	</nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			navigateToAfterLogin: encodeURI(window.location.pathname)
		}
	},
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		])
	},
}
</script>

<style lang="scss" scoped>
	nav {
		position: fixed;
		top: 0;
		width: 100vw;

		background-color: #222222;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
		z-index: 1000;
	}

	.content-wrapper {
		height: 56px;
		width: 90%;
		max-width: 1440px;
		margin: auto;
	}

	.go-back-wrapper {
		p {
			font-size: 15px;
			letter-spacing: 0.5px;
			color: var(--ion-color-primary);
		}

		* {
			margin: 0 20px 0 0;
			transition-duration: 200ms;
		}
	}

	.go-back-wrapper:hover {
		cursor: pointer;
		
		.vertical-line {
			height: 40px;
		}
	}

	.links-wrapper {
		a {
			color: var(--ion-color-primary);
			font-weight: 300;
			padding: 10px;
		}

		a:hover {
			color: var(--ion-color-primary-shade);
			text-decoration: none;
		}
	}
</style>